'use client'

import { Menu } from 'lucide-react'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useState, useEffect } from 'react'

import {
	Sheet,
	SheetTrigger,
	SheetContent,
} from '~/ui/shadcn/ui/sheet'
import { Button } from '~/ui/shadcn/ui/button'
import NavigationItems from '~/app/(app)/_modules/header/navigation-items'
import { cn } from '~/ui/lib/utils'

import type { NavigationItem } from './navigation-items-list'

const MobileSheet = ({
	navigationItemsList,
	disableReviewRequestButton = false,
}: {
	navigationItemsList: NavigationItem[]
	disableReviewRequestButton?: boolean
}) => {
	const [
		open,
		setOpen,
	] = useState(false)

	const pathname = usePathname()

	useEffect(() => {
		setOpen(false)
	}, [
		pathname,
	])

	return (
		<Sheet
			open={open}
			onOpenChange={setOpen}
		>
			<SheetTrigger asChild>
				<Button
					size="icon"
					className="relative size-8 rounded-full border border-black bg-transparent text-black hover:bg-slate-100"
				>
					<Menu className="size-4" />
				</Button>
			</SheetTrigger>
			<SheetContent>
				<div className="w-full p-3">
					<Link href="/">
						<Image
							priority={true}
							alt="Trestle"
							title="Trestle"
							src="/images/trestle-logo.png"
							height={21}
							width={70}
							className="object-contain"
							quality={100}
						/>
					</Link>
					<div className={cn('pt-12', disableReviewRequestButton && 'group is-review-request-button-disabled')}>
						<NavigationItems navigationItemsList={navigationItemsList} />
					</div>
				</div>
			</SheetContent>
		</Sheet>
	)
}

export default MobileSheet
