'use client'

import Link from 'next/link'

import { cn } from '~/ui/lib/utils'
import { varela } from '~/fonts'

import type { LinkItem } from './navigation-items-list'

const MobileNavigationItem = ({
	item,
}: { item: LinkItem }) => {
	const itemHref = typeof item.linkProps.href === 'string' ? item.linkProps.href : item.linkProps.href.pathname

	return (
		<Link
			key={itemHref}
			{...item.linkProps}
			className={cn('flex w-fit items-center gap-3 rounded-full bg-neutral-100 hover:bg-neutral-200 px-5 py-2', item.className, varela.className)}
		>
			{item.icon ? item.icon : null}
			<div className="w-fit text-left text-xl">
				{item.label}
			</div>
		</Link>
	)
}

export default MobileNavigationItem
