'use client'

import { ArrowRight, Mail } from 'lucide-react'
import { useState } from 'react'
import Link from 'next/link'
import posthog from 'posthog-js'

import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from '~/ui/shadcn/ui/dropdown-menu'
import { Button } from '~/ui/shadcn/ui/button'
import { client } from '~/app/_trpc/client'
import { Separator } from '~/ui/shadcn/ui/separator'

import ReviewRequestList from './review-request-list'
import MobileInboxButton from './mobile-inbox-button'

const ReviewRequestInbox = ({
	initialCount,
}: {
	initialCount: number
}) => {
	const trpcContext = client.useUtils()
	const [
		open,
		setOpen,
	] = useState(false)

	const {
		data: numNotifications,
		isInitialLoading,
		isError,
	} = client.reviewRequests.countReviewRequestNotifications.useQuery(undefined, {
		refetchInterval: 60_000,
		initialData: initialCount,
	})

	if (isInitialLoading || isError) {
		return null
	}

	return (
		<>
			<MobileInboxButton numNotifications={numNotifications} />
			<div className="hidden md:block">
				<DropdownMenu
					open={open}
					onOpenChange={async (open) => {
						if (open) {
							await trpcContext.reviewRequests.getReviewRequestNotifications.refetch()
						}
						setOpen(open)
					}}
				>
					<DropdownMenuTrigger asChild>
						<Button
							size="icon"
							className="relative size-8 rounded-full border border-black bg-transparent text-black hover:bg-slate-100"
						>
							<Mail className="size-4" />
							{numNotifications > 0 ? (
								<div className="absolute -end-2 -top-2 inline-flex size-5 items-center justify-center rounded-full bg-red-500 text-xs font-semibold text-white dark:border-gray-900">
									{numNotifications}
								</div>
							) : null}
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent>
						<ReviewRequestList />
						<Separator />
						<div className="flex w-full items-center justify-between p-2 pl-4">
							<p className="p-1 text-xs">
								{`${numNotifications} total review requests`}
							</p>
							<Link
								href="/inbox"
								onClick={() => {
									setOpen(false)
									posthog.capture('inbox_visit', {
										device: 'Desktop',
									})
								}}
							>
								<Button
									variant="ghost"
									size="xs"
									className="text-xs"
								>
									Go to Inbox
									{' '}
									<ArrowRight className="size-3" />
								</Button>
							</Link>
						</div>
					</DropdownMenuContent>
				</DropdownMenu>
			</div>
		</>
	)
}

export default ReviewRequestInbox
