'use client'

import {
	AlertCircle,
	XIcon,
	ArrowRightIcon,
	Loader2Icon,
} from 'lucide-react'
import Link from 'next/link'

import HelperText from '~/modules/typography/helper-text'
import { DropdownMenuItem, DropdownMenuLabel } from '~/ui/shadcn/ui/dropdown-menu'
import { Button } from '~/ui/shadcn/ui/button'
import { client } from '~/app/_trpc/client'
import { Separator } from '~/ui/shadcn/ui/separator'
import { dateTimeFormat } from '~/utils/date-time-format'

const ReviewRequestList = () => {
	const trpcContext = client.useUtils()

	const {
		data: notifications,
		isLoading: isLoadingNotifications,
	} = client.reviewRequests.getReviewRequestNotifications.useQuery({
		limit: 5,
	})

	const {
		mutateAsync: dismissReviewRequest,
		isLoading: isLoadingDismissal,
	} = client.reviewRequests.dismissReviewRequest.useMutation({
		onSuccess: async () => {
			await trpcContext.reviewRequests.invalidate()
		},
	})

	if (isLoadingNotifications || !notifications) {
		return (
			<div className="px-6 py-3 text-center">
				<Loader2Icon className="mx-auto animate-spin" />
			</div>
		)
	}

	if (notifications.length === 0) {
		return (
			<div className="px-6 py-3 text-center">
				No Pending Requests
			</div>
		)
	}

	return (
		<div className="space-y-2">
			<DropdownMenuLabel className="flex flex-row items-end justify-between">
				Recent Pending Requests
			</DropdownMenuLabel>
			<Separator />
			{notifications.map((notification) => {
				return (
					<DropdownMenuItem
						asChild
						key={notification.id}
					>
						<div className="flex cursor-pointer items-center justify-between space-x-6 hover:bg-none">
							<Link
								className="flex grow flex-row items-center justify-between gap-6"
								href={`/submit-review/${notification.group.vendor.id}?shortCode=${notification.shortCode}`}
							>
								<span className="flex flex-col items-start gap-1">
									<div className="flex flex-row items-center gap-2">
										<AlertCircle className="text-amber-400" />
										<div>
											<div className="flex flex-row items-center gap-2">
												{notification.group.vendor.name}
												<small className="text-slate-400">
													{notification.group.vendor.ein}
												</small>
											</div>
											<HelperText>
												{notification.group.team.name}
												{' '}
												Team
											</HelperText>
										</div>
									</div>
								</span>
								<span className="flex flex-row items-center gap-2">
									<small className="text-slate-400">
										{dateTimeFormat(notification.createdAt, true)}
									</small>
								</span>
							</Link>
							<div className="flex items-center gap-3">
								<Button
									asChild
									variant="outline"
									size="icon"
									className="size-8"
								>
									<Link href={`/submit-review/${notification.group.vendor.id}?shortCode=${notification.shortCode}`}>
										<ArrowRightIcon className="size-4" />
									</Link>
								</Button>
								<Button
									variant="destructive"
									size="icon"
									className="size-8"
									onClick={async () => {
										await dismissReviewRequest({
											reviewRequestId: notification.id,
										})
										await trpcContext.reviewRequests.invalidate()
									}}
								>
									{isLoadingDismissal ? (
										<Loader2Icon className="size-4" />
									) : (
										<XIcon className="size-4" />
									)}
								</Button>
							</div>
						</div>
					</DropdownMenuItem>
				)
			})}
		</div>
	)
}

export default ReviewRequestList
