import { Mail } from 'lucide-react'
import Link from 'next/link'
import posthog from 'posthog-js'

import { Button } from '~/ui/shadcn/ui/button'

const MobileInboxButton = ({
	numNotifications = 0,
}: {
	numNotifications: number
}) => {
	return (
		<Link
			href="/inbox/mobile"
			className="md:hidden"
		>
			<Button
				size="icon"
				className="relative size-8 rounded-full border border-black bg-transparent text-black hover:bg-slate-100"
				onClick={() => {
					posthog.capture('inbox_visit', {
						device: 'Mobile',
					})
				}}
			>
				<Mail className="size-4" />
				{numNotifications > 0 ? (
					<div className="absolute -end-2 -top-2 inline-flex size-5 items-center justify-center rounded-full bg-red-500 text-xs font-semibold text-white dark:border-gray-900">
						{numNotifications}
					</div>
				) : null}
			</Button>
		</Link>
	)
}

export default MobileInboxButton
