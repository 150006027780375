import type { NavigationItem } from '~/app/(app)/_modules/header/navigation-items-list'

import { Separator } from '~/ui/shadcn/ui/separator'

import MobileNavigationItem from './mobile-navigation-item'

const NavigationItems = ({
	navigationItemsList,
}: {
	navigationItemsList: NavigationItem[]
}) => {
	return (
		<div className="flex flex-col gap-3">
			{navigationItemsList.map((item) => {
				if (!item.displaySettings.mobile) {
					return null
				}

				if (item.type === 'divider') {
					return <Separator key={item.label} />
				}

				return (
					<MobileNavigationItem
						key={item.label}
						item={item}
					/>
				)
			})}
		</div>
	)
}

export default NavigationItems
