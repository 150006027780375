'use client'

import { useState } from 'react'
import Link from 'next/link'
import { motion } from 'framer-motion'
import { usePathname } from 'next/navigation'

import { Button } from '~/ui/shadcn/ui/button'
import { cn } from '~/ui/lib/utils'

import type { LinkItem } from './navigation-items-list'

const DesktopNavigationItem = ({
	item,
}: {
	item: LinkItem
}) => {
	const pathname = usePathname()

	const [
		hoveredNavigationItem,
		setHoveredNavigationItem,
	] = useState<string | null>(null)

	const [
		clickedNavigationItem,
		setClickedNavigationItem,
	] = useState<string | null>(null)

	const itemHref = typeof item.linkProps.href === 'string' ? item.linkProps.href : item.linkProps.href.pathname

	if (!itemHref) {
		return null
	}

	const isHighlighted = hoveredNavigationItem === itemHref ||
    clickedNavigationItem === itemHref ||
    itemHref !== '/' &&
      pathname.startsWith(itemHref) &&
      !clickedNavigationItem &&
      !hoveredNavigationItem

	return (
		<div
			key={itemHref}
			onMouseEnter={() => {
				setHoveredNavigationItem(itemHref)
			}}
			onMouseLeave={() => {
				setHoveredNavigationItem(null)
			}}
			className="relative"
		>
			<Link
				{...item.linkProps}
				className="relative z-20 inline-block rounded-full bg-transparent no-underline hover:bg-transparent"
			>
				<Button
					className={cn(
						'rounded-full bg-transparent hover:bg-transparent text-black/80 group-hover:text-black shadow-none',
						isHighlighted && 'text-black',
					)}
					onClick={() => {
						setClickedNavigationItem(itemHref)
					}}
				>
					{item.label}
				</Button>
			</Link>
			{isHighlighted ? (
				<motion.span
					layoutId="hover"
					transition={{
						type: 'spring',
						duration: 0.4,
					}}
					className="absolute inset-0 size-full rounded-full bg-gray-200 transition-colors duration-500"
				/>
			) : null}
		</div>
	)
}

export default DesktopNavigationItem
